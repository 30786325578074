<template>
    <v-container grid-list-md pa-0 relative>
         <v-layout row wrap>
             <v-flex xs12>
                 <v-card outlined class="primary--border">
                     <v-card-title class="title">
                         Individual Heads
                         <v-spacer />
                         <v-download-column-select
                            v-if="individualFeeHeadData.length > 0"
                            :showPDF="false"
                            :downloadColumns="dynamicColumns"
                            @onDownload="downloadRecord"
                        >
                            DOWNLOAD INDIVIDUAL ECA HEAD RECORD
                        </v-download-column-select>
                     </v-card-title>
                     <v-card outlined>
                         <v-card-title class="pb-0">
                             <v-flex xs4>
                                 <v-select
                                     :loading="loadingGrade"
                                     :disabled="grades.length < 1"
                                     @change="getSections()"
                                     :items="grades"
                                     outlined
                                     dense
                                     class="pa-0"
                                     label="Select grade"
                                     v-model="grade"
                                 />
                             </v-flex>
                             <v-flex xs4>
                                 <v-select
                                     :loading="loadingSection"
                                     :disabled="sections.length < 1"
                                     :items="sections"
                                     @change="getIsAllowedFeeheads()"
                                     class="pa-0"
                                     outlined
                                     dense
                                     label="Select section"
                                     v-model="section"
                                 />
                             </v-flex>
                         </v-card-title>
                     </v-card>
                     <v-data-table
                         :headers="headers"
                         hide-default-footer
                         :items="individualFeeHeadData"
                         :server-items-length="individualFeeHeadData.length"
                     >
                         <template v-slot:item="{ index, item }">
                             <tr :key="index">
                                 <td>
                                     {{ item.name }}
                                 </td>
                                 <td class="text-right">
                                     <span v-for="(ecaHead, key) in allowedEcaHead" :key="key">
                                         <v-chip label :color="!!item.individual_fee_head.filter((item)=>item.is_removed===0).find((item)=> (item.id) === ecaHead.id) ? 'primary' : ''" class="mr-2" @click="addOrRemoveEca(item, ecaHead, key) ">
                                             <v-avatar v-if="!!item.individual_fee_head.filter((item)=>item.is_removed===0).find((item)=> (item.id) === ecaHead.id)">
                                                 <v-icon small>check_circle</v-icon>
                                             </v-avatar>
                                             {{ ecaHead.title }}
                                         </v-chip>
                                     </span>
                                 </td>
                             </tr>
                         </template>
                     </v-data-table>
                     <edit-button
                     v-if="individualFeeHeadData.length>0"
                         :loading="saving"
                         editText="Update"
                         :disabled="
                             !isChanged || saving
                         "
                         label="Update student's individual head"
                         @agree="updateStudentFeeHead"
                         permission="student-update"
                         :large="true"
                         :block="true"
                         >
                     </edit-button>
                 </v-card>
             </v-flex>
         </v-layout>
    </v-container>
 </template>
 
<script>
import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from "../../../../library/helpers";

 
 export default{
     mixins: [Mixins],
     data: () => ({
         isChanged: false,
         saving: false,
         grades: [],
         grade: "",
         section: "",
         sections: [],
         loadingGrade: false,
         loadingSection: false,
         subjectList: [],
         allStudent: [],
         allowedEcaHead: [],
         individualFeeHeadData: [],
         headers: [
             {
                 text: "Name",
                 value: "name",
                 align: "left",
                 sortable: false,
                 width: 200,
             },
             { text: "", value: "", sortable: false, align: "right" },
         ],
         downloadColumns: [
         {
             value: "student_name",
             name: "Student Name",
             selected: false,
         },
         {
             value: "class_section",
             name: "Class / Section",
             selected: false,
         },
         {
             value: "fee_head",
             name: "Fee Head",
             selected: false,
         },
         {
             value: "amount",
             name: "Amount",
             selected: false,
         },
         {
             value: "address",
             name: "Address",
             selected: false,
         },
         {
             value: "contact",
             name: "Contact",
             selected: false,
         },
        
      
         ],
     }),
     computed:{
         dynamicColumns() {
         let columns = [];
         this.downloadColumns.map((item) => {
             if (item.toAdmin === true && this.$auth.isAdmin()) {
                 columns.push(item);
             } else if (!item.toAdmin) {
                 columns.push(item);
             }
         });
         return columns;
     },
     },
     mounted() {
         this.getGrades();
     },
     methods:{
         getGrades() {
         this.sections = [];
         this.section = "";
         this.loadingGrade = true;
         this.$rest
             .get(
             "/api/grades?itemsPerPage=100&sortBy=rank&descending=false&slim=true"
             )
             .then(({ data }) => {
             this.grades = data.data.map((item) => {
                 return { value: item.id, text: item.name };
             });
             })
             .finally((err) => {
             this.loadingGrade = false;
             });
         },
         getSections() {
         this.section = "";
         this.sections = [];
         this.loadingSection = true;
         this.$rest
             .get("/api/section?batch=" + this.batchId + "&grade=" + this.grade)
             .then(({ data }) => {
             this.sections = data.data.map((item) => {
                 return { value: item.id, text: item.name };
             });
             })
             .finally(() => {
             this.loadingSection = false;
             });
         },
 
         getIsAllowedFeeheads(){
             this.$rest.get("/api/allowed-fee-head")
             .then(({data})=>{
                 this.allowedEcaHead = data.data
                 this.getIndividualFeeHead()
             })
         },
         getIndividualFeeHead(){
             this.$rest.get('/api/individual-eca-head?sectionId='+this.section)
             .then(({data})=>{
                 this.individualFeeHeadData = data.data
             })
         },
         addOrRemoveEca(item, ecaHead) {
             this.isChanged = true;
             const removeIndex = item.individual_fee_head.findIndex((head) => (head.id === ecaHead.id))
             if(removeIndex !==- 1) {
                 if(item.individual_fee_head[removeIndex].student_fee_head_id) {
                     item.individual_fee_head[removeIndex].is_removed = item.individual_fee_head[removeIndex].is_removed === 1 ? 0 : 1;
                 } else {
                     item.individual_fee_head.splice(removeIndex, 1)
                 }
             } else {
                     item.individual_fee_head.push({...ecaHead,is_removed: 0});                
             }
         },
         updateStudentFeeHead(){
             let uploadFeedheadData = this.individualFeeHeadData.filter((item)=>{
                 if(item.individual_fee_head.length > 0) return item;
             })
             this.$rest.post('/api/individual-eca-head',{data:uploadFeedheadData})
             .then((res)=>{
                 this.$events.fire("notification", { message: res.data.message });
                 this.isChanged = false;
                 this.getIndividualFeeHead();
             })
 
         },
         downloadRecord({ type, columns, columnString }) {
             console.log(columnString, 'colstring')
             redirectDownloadUrl({
                 uri: "/download/billing/individaul-eca-report",
                 queryString: `columns=${columnString}&grade_id=${this.grade}&section_id=${this.section}`,
             });
         },
     }
 }
 </script>
 
 
 <style lang="scss">
 .subject_list_group {
   .v-chip,
   .v-chip .v-chip__content {
     border-radius: 2px;
   }
 }
 
 .optional-subject {
   .v-chip {
     margin-left: 0;
   }
 
   .v-chip--small {
     height: 20px !important;
     padding: 0 !important;
   }
 
   .v-chip--removable .v-chip__content {
     padding: 7px 0px 7px 5px !important;
   }
 
   .v-chip__content {
     font-size: 10px;
   }
 }
 </style>